export const playingConstants = {
    GET_PLAYING_TRACK_REQUEST: 'GET_PLAYING_TRACK_REQUEST',
    GET_PLAYING_TRACK_SUCCESS: 'GET_PLAYING_TRACK_SUCCESS',
    GET_PLAYING_TRACK_FAILURE: 'GET_PLAYING_TRACK_FAILURE',

    SET_PLAYING_TRACK_REQUEST: 'SET_PLAYING_TRACK_REQUEST',
    SET_PLAYING_TRACK_SUCCESS: 'SET_PLAYING_TRACK_SUCCESS',
    SET_PLAYING_TRACK_FAILURE: 'SET_PLAYING_TRACK_FAILURE',

    PLAY: 'PLAY_TRACK',
    PAUSE: 'PAUSE_TRACK'
};