export const userConstants = {
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_FAILED: 'GET_USER_FAILED',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',

    GET_USER_FAV_TRACKS_REQUEST: 'GET_USER_FAV_TRACKS_REQUEST',
    GET_USER_FAV_TRACKS_FAILED: 'GET_USER_FAV_TRACKS_FAILED',
    GET_USER_FAV_TRACKS_SUCCESS: 'GET_USER_FAV_TRACKS_SUCCESS',

    GET_PLAYLISTS_REQUEST: 'GET_PLAYLISTS_REQUEST',
    GET_PLAYLISTS_SUCCESS: 'GET_PLAYLISTS_SUCCESS',
    GET_PLAYLISTS_FAILED: 'GET_PLAYLISTS_FAILED',

    GET_LIKED_TRACKS_REQUEST: 'GET_LIKED_TRACKS_REQUEST',
    GET_LIKED_TRACKS_SUCCESS: 'GET_LIKED_TRACKS_SUCCESS',
    GET_LIKED_TRACKS_FAILED: 'GET_LIKED_TRACKS_FAILED',

    GET_LIKED_ARTISTS_REQUEST: 'GET_LIKED_ARTISTS_REQUEST',
    GET_LIKED_ARTISTS_SUCCESS: 'GET_LIKED_ARTISTS_SUCCESS',
    GET_LIKED_ARTISTS_FAILED: 'GET_LIKED_ARTISTS_FAILED',

    GET_RECENTLY_PLAYED_TRACKS_REQUEST: 'GET_RECENTLY_PLAYED_TRACKS_REQUEST',
    GET_RECENTLY_PLAYED_TRACKS_SUCCESS: 'GET_RECENTLY_PLAYED_TRACKS_SUCCESS',
    GET_RECENTLY_PLAYED_TRACKS_FAILED: 'GET_RECENTLY_PLAYED_TRACKS_FAILED'
};
